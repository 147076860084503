.tab-title {
  /* font-size: 20px; */
  font-weight: bold;
  padding: 5px 0px;
  /* text-align: justify; */
  color: #00bbaa;
}

.tab-content {
  text-align: justify;
}

.tab-col {
  margin: 0px 0px 5px 0px;
  display: inline-flex;
  /* color: #6e6e6e; */
}
.tab-icon {
  display: inline-flex;
  margin-top: 10px;
  margin-right: 10px;
  font-size: 35px;
  color: #00bbaa;
  /* vertical-align: middle; */
}
