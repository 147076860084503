@import "~magnific-popup/dist/magnific-popup.css";

body {
  margin: 0px auto;
  font-family: "Titillium Web", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* max-width: 1024px; */
  /* color: #4f5866; */
  color: #302e2e;

  /* color: #685f58; */
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  line-height: 150%;
}
