.quote-bg {
  /* padding: 10px; */
  /* height: 60vh; */

  font-family: "Titillium Web", sans-serif;
  padding-bottom: 10px;
}

.quote-phone {
  /* background-color: salmon; */
  /* margin-left: -50px; */
  margin-top: 10px;

  /* padding: 10px; */
  font-weight: bold;

  color: #f5f5f5;
  font-size: 25px;
  font-weight: bold;
  background-color: #eb0143;
  padding: 5px;
  text-align: center;

  /* clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 10% 50%, 0 0); */

  /* clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%); */
}

.quote-bottom {
  text-align: center;
}
.send-btn {
  background-color: #00afef !important;
  color: #f5f5f5 !important;
}

.quote-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 0px !important;
}

.quote-field {
  margin: 7px 0px !important;
  padding: 0px 25px !important;
}
