/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Raleway;
  background-color: #202125;
} */

.our-products {
  /* background-color: saddlebrown; */
  margin-bottom: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

/* mobile */
.our-products-container-m {
  padding: 0px 5px 0px 5px;
  /* width: 100vw; */
  margin: auto;
}

.img-box-m {
  /* border: 1px solid #000; */
  border-radius: 10px;
  box-sizing: content-box;
  margin: 10px;
  height: 300px;
  /* width: 70vw; */
  overflow: hidden;
  display: inline-block;
  color: white;
  position: relative;
  background-color: white;
}

.img-box-m .transparent-box {
  background-color: rgba(0, 0, 0, 0.1);
}

.img-box-m .caption {
  transform: translateY(-20px);
  opacity: 1;
}

/* tablet */
.our-products-container-t {
  padding: 0px 10px 0px 10px;
  width: 100vw;
  margin: auto;
}

.img-box-t {
  /* border: 1px solid #000; */
  border-radius: 10px;
  box-sizing: content-box;
  margin: 10px;
  height: 300px;
  /* width: 90vw; */
  overflow: hidden;
  display: inline-block;
  color: white;
  position: relative;
  background-color: white;
}

.img-box-t .transparent-box {
  background-color: rgba(0, 0, 0, 0.1);
}

.img-box-t .caption {
  transform: translateY(-20px);
  opacity: 1;
}

/* desktop */
.our-products-container {
  padding: 0px 0px 0px 0px;
  width: 1024px;
  margin: auto;
}

.our-products-title {
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 7px;
}

.our-products-sub-title {
  font-size: 17px;
  text-align: center;
  padding-bottom: 35px;
}

.our-products-card {
  margin-bottom: 35px;
}

.gallery-image {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery-image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  transform: scale(1);
  transition: transform 0.4s ease;
}

.img-box {
  /* border: 1px solid #000; */
  border-radius: 10px;
  box-sizing: content-box;
  margin: 10px;
  height: 300px;
  /* width: 30vw; */
  overflow: hidden;
  display: inline-block;
  color: white;
  position: relative;
  background-color: white;
}

.caption {
  position: absolute;
  bottom: 5px;
  left: 20px;
  opacity: 0;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.transparent-box {
  height: 300px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease;
}

.img-box:hover img {
  transform: scale(1.1);
}

.img-box:hover .transparent-box {
  background-color: rgba(0, 0, 0, 0.5);
}

.img-box:hover .caption {
  transform: translateY(-20px);
  opacity: 1;
}

.img-box:active .caption {
  transform: translateY(-20px);
  opacity: 1;
}

.img-box:hover {
  cursor: pointer;
}

.caption > p:nth-child(2) {
  font-size: 0.8em;
}

.opacity-low {
  opacity: 0.5;
}

/* .our {

}

.our-grid {
  margin: 0px !important;
  padding: 0px !important;
}

.our-section-01 {
  background: lightcoral;
}

.our-section-02 {
  background: lightgreen;
}

.our-section-03 {
  background: lightseagreen;
}

.our-section-04 {
  background: lightsteelblue;
} */
