.sale-bg {
  position: relative;
  display: inline-block;
  background-color: #fbd62b;
  color: #282828;
  padding: 0px 0px 0px 30px;
  font-weight: bold;

  /* clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%); */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  /* -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset 0 0 10px #000000; */

  text-transform: uppercase;
  border: 2px solid #222;
}

.sale-season {
  font-size: 18px;

  position: absolute;
  bottom: -20px;
  left: 0;
  /* margin-left: 10px;
  margin-right: 10px; */

  transform: rotate(270deg);
  transform-origin: 0 0;
}

.sale-details {
  background-color: #f73858;
  color: #f5f5f5;
  padding: 8px 5px;
}

.sale-normal {
  font-size: 12px;
  line-height: 120%;
}

.sale-high {
  font-size: 34px;
  line-height: 120%;
}

.rotate {
  /* transform: rotate(-25deg); */
  /* transform: skew(140deg, 170deg); */
}
