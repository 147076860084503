.main-content {
  max-width: 1366px;
  margin: 0px auto;
  /* margin-left: 75px; */
}

.app-icon-bar {
  position: fixed;
  z-index: 999;
  bottom: 15px;
  right: 5px;

  /* width: 50px;
  height: 50px; */

  /* transform: translate(0px, 0%) rotate(-90deg); */
  /* transform-origin: left;
  transform: rotate(90deg); */

  /* transform: rotate(-90deg) translateX(0px); */
  /* transform: translateX(0px); */
  /* -webkit-transform: translateY(-50%); */
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
}

/* .app-icon-bar a { */
/* display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px; */
/* z-index: -1; */
/* opacity: 0.9; */
/* } */

/* .app-phone-content {
  width: 61px;
  height: 61px;
  background: #f3f7f9; */

/* border-radius: 50px; */
/* border-radius: 5px 5px 0px 0px; */

/* border-top: 1px solid #a1a1a1;
  border-left: 1px solid #a1a1a1;
  border-right: 1px solid #a1a1a1; */

/* text-align: center;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); */
/* border-radius: 50px; */
/* } */

.app-phone-outter {
  /* background: #523de7; */

  width: 61px;
  height: 61px;
  background: #f3f7f9;
  /* background: #f3f7f9; */

  border-radius: 50px;

  text-align: center;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.app-phone-outter:hover {
  -webkit-transform: rotate(15deg) scale(1);
  transform: rotate(15deg) scale(1);
}

.app-phone {
  /* align-items: center; */
  margin: 13px auto;
  /* color: #f83758; */
  color: #00afef;

  font-size: 35px;
}

/* .app-icon-bar a:hover {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
} */

.facebook {
  background: #3b5998;
  color: white;
}

.twitter {
  background: #55acee;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

.app-content {
  margin-left: 75px;
  font-size: 30px;
}
