.com02-title {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 5px;
  color: #00bbaa;
  text-transform: uppercase;
}

.com02-item {
  /* width: 50%; */

  text-align: center;
  color: red;
}
