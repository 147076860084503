.f-body {
  font-size: 12px;
  font-weight: bolder;
  /* color: #57585b; */
  text-align: center;
  padding: 20px 0px;
  margin: 0px;
  background-color: #3b3c3e;
  color: #b5b5b5;
  border-radius: 0;
}
