/* mobile */
.slogan-m {
  background-color: #282828;
  color: #fbd62b;
  font-size: 17px;
  text-align: justify;
  display: inline-flex;
  padding: 5px 0px;
  width: 100%;
}
.slogan-01-m {
  height: auto;
  width: 70px;
  object-fit: cover;
  vertical-align: middle;
  margin: auto;
}

.slogan-02-m {
  vertical-align: middle;
  margin: auto auto;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  background: linear-gradient(315deg, #7ee8fa 0%, #80ff72 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px 4px #000000);
}

/* tablet */
.slogan-t {
  background-color: #282828;
  color: #fbd62b;
  font-size: 17px;
  text-align: justify;
  display: inline-flex;
  padding: 5px 0px;
  width: 100%;
}
.slogan-01-t {
  height: auto;
  width: 120px;
  object-fit: cover;
  vertical-align: middle;
  margin: auto;
}

.slogan-02-t {
  vertical-align: middle;
  margin: auto auto;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  background: linear-gradient(315deg, #7ee8fa 0%, #80ff72 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px 4px #000000);
}

/* desktop */
.slogan {
  background-color: #282828;
  color: #fbd62b;
  padding: 10px;
  font-size: 22px;
  text-align: justify;
  display: inline-flex;
  width: 100%;
  padding: 5px 0px;
}

.slogan-01 {
  height: auto;
  width: 170px;
  object-fit: cover;
  vertical-align: middle;
  margin: auto;
}

.slogan-02 {
  vertical-align: middle;
  margin: auto auto;
  text-align: center;
  font-size: 60px;
  font-weight: bold;
  background: linear-gradient(315deg, #7ee8fa 0%, #80ff72 74%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(2px 2px 4px #000000);
}
