.promotion-content {
  background: #2e2c2c;
}

.promotion-col-m {
  margin: 0px auto;
  /* color: #6e6e6e; */
  /* background: #425432; */
  text-align: center;
}

.promotion-icon-m {
  display: inline-flex;
  /* margin-top: 5px; */
  /* margin-right: 10px; */
  font-size: 40px;
  /* vertical-align: top !important; */
  /* text-align: center; */
  color: #01afef;
  padding: 0px;
  margin: 0px;
  /* background: palegoldenrod; */
}

.promotion-title-m {
  /* display: inline-flex; */
  /* background: palegreen; */
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
}

.promotion-title {
  font-size: 18px;
  font-weight: bold;
  /* color: #523de7; */
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
.promotion-subtitle {
  text-align: justify;
  color: #ccc;
}

.promotion-col-outter {
  width: 100%;
  text-align: center;
  /* margin: auto auto; */
  /* background: #00afef; */
}

.promotion-col {
  margin: 15px auto;
  display: inline-flex;
  /* color: #6e6e6e; */

  /* align-items: center; */
  /* text-align: center; */
  /* background: palegreen; */
}
.promotion-icon {
  display: inline-flex;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 40px;
  vertical-align: top !important;
  color: #01afef;
  /* width: 100px; */
}
.promotion-body {
  /* display: inline-flex; */
}
