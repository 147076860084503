/* body {margin:0;font-family:Arial} */

.pulled {
  color: #00bbaa;
  font-size: 12px;
}

.outbound-m {
  margin-bottom: 50px;
  /* padding-bottom: 100px; */
  /* background-color: #fff; */
  /* width: 100vw; */
}
.outbound {
  /* position: fixed;
  top: 0px;
  width: 100%; */
  top: 0;
  position: fixed;
  z-index: 999;
  background-color: #fff;
  border-bottom: 5px solid rgba(104, 95, 88, 0.5);
  width: 100%;
}

.branch {
  /* font-size: 17px;
  float: left;
  overflow: hidden; */

  /* color: white; */
  /* background-color: #fff; */
  padding: 0px !important;
  /* padding: 14px 16px; */
  margin: 0px 20px 0px 10px !important;
  /* background-color: #4caf50; */
  /* margin-left: 10px;
  margin-right: 30px; */
}

.n-logo {
  /* float: left;
  overflow: hidden; */
  /* width: 125px; */
  height: 48px;
  /* margin-left: 10px;
  margin-right: 30px; */

  justify-items: center;
  vertical-align: bottom;
  cursor: pointer;
}

.topnav {
  overflow: hidden;

  color: #302e2e;

  /* font-weight: bold; */
  max-width: 1366px;
  margin: auto;
}

.topnav a {
  float: left;
  display: block;
  color: #302e2e;
  text-align: center;
  padding: 12px 16px;
  text-decoration: none;
  /* font-size: 17px; */
  text-transform: uppercase;
  font-size: 15px;
}

.active {
  background-color: #01afef;
  color: white;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  text-transform: uppercase;
  font-size: 15px;
  border: none;
  outline: none;

  color: #302e2e;
  /* font-weight: bold; */

  padding: 15px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: auto;
  vertical-align: bottom;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: #555;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: #555;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@media screen and (max-width: 768px) {
  /* .outbound-m {
    margin-bottom: 0px;
    position: relative;
  }
  .outbound {
    position: relative;
  } */

  /* .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  } */

  .topnav a,
  .dropdown .dropbtn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  /* .dropdown-content {
    background-color: #333;
    color: #fff;
  }

  .dropdown-content a {
    background-color: #333;
    color: #fff;
  } */

  /* .dropdown-content a::before {
    content: "\2192 ";
    padding-left: 5px;
  } */

  .dropdown-content a:hover {
    background-color: #333;
    color: #fff;
  }
}

@media screen and (max-width: 768px) {
  .topnav.responsive {
    position: relative;
  }
  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive .dropdown {
    float: none;
  }
  .topnav.responsive .dropdown-content {
    position: relative;
    /* display: block; */
  }
  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}
