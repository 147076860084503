.section02 {
  /* background-color: sandybrown; */
}

/* mobile */
.section02-container-m {
  padding: 50px 0px 0px 0px;
  margin: auto;
  /* background-color: seagreen; */
}

.section02-image-group-m {
  position: relative;
  top: 0px;
  left: 0px;
  height: 60vw;
  width: 85vw;
  margin-bottom: 50px;
}

.section02-image01-m {
  position: relative;
  top: 0;
  left: 0;
  border-radius: 10px;
  /* border: 2px solid #fff; */
  width: 40vw;
  height: 25vw;
  object-fit: cover;
}
.section02-image02-m {
  position: relative;
  top: 5vw;
  left: 5vw;
  border-radius: 10px;
  /* border: 2px solid #fff; */
  width: 40vw;
  height: 25vw;
  object-fit: cover;
}
.section02-image04-m {
  position: absolute;
  top: 30vw;
  left: 12vw;
  border-radius: 10px;
  border: 2px solid #fff;
  width: 25vw;
  height: 15vw;
  object-fit: cover;
}
.section02-image03-m {
  position: absolute;
  top: 35vw;
  left: 25vw;
  border-radius: 10px;
  /* border: 2px solid #fff; */
  width: 40vw;
  height: 25vw;
  object-fit: cover;
}

/* tablet */
.section02-container-t {
  padding: 50px 0px 0px 0px;
  width: 95vw;
  margin: auto;
  /* background-color: seagreen; */
}

/* desktop */
.section02-container {
  padding: 100px 20px 0px 0px;
  width: 1024px;
  margin: auto;
  /* background-color: seagreen; */
}

.section02-title {
  font-size: 30px;
  font-weight: bold;
  margin-top: 2rem;
  color: #00bbaa;
}

.section02-subtitle {
  font-size: 17px;
  text-align: justify;
}

.section02-subtitle-item {
  margin-top: 15px;
  text-align: justify;
}

.section02-center {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
}

.section02-outbound {
  margin-bottom: 80px;
  /* vertical-align: middle;
  height: 1000px; */
  /* background-color: #f9f9fb; */
  /* clip-path: polygon(25% 10%, 100% 10%, 75% 90%, 0% 90%); */
}

.section02-image-group {
  position: relative;
  top: 0px;
  left: 0px;
  height: 350px;
  width: 470px;
  margin-bottom: 50px;
}
.section02-image01 {
  position: relative;
  top: 0px;
  left: 0px;
  border-radius: 10px;
  /* border: 2px solid #fff; */
  width: 225px;
  height: 150px;
  object-fit: cover;
}

.section02-image02 {
  position: relative;
  top: 20px;
  left: 20px;
  border-radius: 10px;
  /* border: 2px solid #fff; */
  width: 225px;
  height: 150px;
  object-fit: cover;
}
.section02-image04 {
  position: absolute;
  top: 170px;
  left: 50px;
  border-radius: 10px;
  border: 2px solid #fff;
  width: 160px;
  height: 100px;
  object-fit: cover;
}

.section02-image03 {
  position: absolute;
  top: 200px;
  left: 150px;
  border-radius: 10px;
  /* border: 2px solid #fff; */
  width: 225px;
  height: 150px;
  object-fit: cover;
}
